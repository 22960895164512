/* eslint-disable no-use-before-define */
// min:5 https://www.youtube.com/watch?v=5LrDIWkK_Bc&list=PLZlA0Gpn_vH85jM1TWO6TdCtSr6ruglWn&index=19
import { useEffect, useState, Suspense } from 'react'
import { useRoutes, useSearchParams, useLocation } from 'react-router-dom'
import { toast, ToastBar, Toaster } from 'react-hot-toast'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import { CssBaseline, GlobalStyles, ThemeProvider, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Client } from '@twilio/conversations'
import ChatClient from 'util/ChatClient'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { generateCustomToken } from './apis/auth'
import { ReaderProvider } from './contexts/ReaderContext'
import { useCurrentPractice } from './contexts/PracticeContext'
import useScrollReset from './hooks/useScrollReset'
import useSettings from './hooks/useSettings'
import routes from './routes'
import { createCustomTheme } from './theme'
import SettingsDrawer from './components/SettingsDrawer'
import Hooks from './hooks'
import DelightedSurvey from './components/DelightedSurvey'
import { authClient, signInWithCustomToken } from './lib/firebase'
import { setContext, setIsAuthCheckUsingTokenLoading } from './slices/auth'
import { selectUser } from './selectors/auth'
import './App.css'
import { configureScopeWithUserId } from './util/sentry'
import { isUnifiedDomain, validateUser } from './util/unified'
import { useEffectDebugger } from './util/debug'
import ResourceLoading from './components/resource-loading'
import { UNIFIED_CONFIG } from './config'
import { normalizeURL } from './util/util'
import { lazyWithRetry } from 'util/lazy-with-retry'
import { ChatWidget } from 'components/dashboard/ChatWidget'

declare global {
	interface Window {
		dataLayer: unknown[]
	}
}

const queryClient = new QueryClient()

const App = () => {
	const content = useRoutes(routes)
	const { settings } = useSettings()
	const user = useSelector(selectUser)
	const [isChatInitialized, setIsChatInitialized] = useState(false)
	const [currentChatPracitceId, setCurrentChatPracitceId] = useState<string | null>(null)
	const [chatClient, setChatClient] = useState<Client | null>(null)
	const [searchParams, setSearchParams] = useSearchParams()
	const token = searchParams.get('redirect_token')
	const [isRedirectInProgress, setIsRedirectInProgress] = useState(false)
	const dispatch = useDispatch()
	useScrollReset()
	const { currentPracticeObject, currentConsolidator } = useCurrentPractice()
	const location = useLocation()
	const baseURL = normalizeURL(UNIFIED_CONFIG.baseURL)
	const pathname = normalizeURL(location.pathname)

	useEffect(() => {
		if (!token) {
			dispatch(setIsAuthCheckUsingTokenLoading(false))
		}
		if (token) {
			signInWithCustomToken(token)
				.catch((err) => {
					console.error(err)
					dispatch(setIsAuthCheckUsingTokenLoading(false))
				})
				.finally(() => {
					searchParams.delete('redirect_token')
					setSearchParams(searchParams)
				})
		}
	}, [])

	useEffect(() => {
		// check if the user/practice should be redirected
		if (!user?.uid) {
			return // If we don't have a user don't bother
		}

		// If we don't have the redirectDashboard flag
		if (
			!(
				currentPracticeObject?.settings?.redirectDashboard ||
				currentConsolidator?.settings?.redirectDashboard
			)
		) {
			return
		}

		// this means the redirect is already done or not needed
		if (isUnifiedDomain()) {
			return
		}
		if (isRedirectInProgress) {
			return
		}
		setIsRedirectInProgress(true)
		generateCustomToken()
			.then((res) => {
				// redirect the user to the new url
				const newURL = `${baseURL}${pathname}?redirect_token=${res.data.customToken}`
				window.location.href = newURL
			})
			.catch(console.error)
	}, [
		currentPracticeObject?.settings?.redirectDashboard,
		currentConsolidator?.settings?.redirectDashboard,
		user?.uid,
		isRedirectInProgress
	])

	useEffectDebugger(() => {
		authClient().onAuthStateChanged(
			async (u) => {
				if (u) {
					const context = await validateUser(u)
					dispatch(setContext(context))
					if (context) {
						configureScopeWithUserId(u.uid, u.email)
					}
				} else {
					dispatch(setContext(null))
				}
			},
			(err) => console.error(err)
		)
	}, [])

	const theme = createCustomTheme({
		compact: settings.compact,
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		roundedCorners: settings.roundedCorners,
		theme: settings.theme
	})

	const useStyles = (
		<GlobalStyles
			styles={{
				'*': {
					boxSizing: 'border-box',
					margin: 0,
					padding: 0
				},
				html: {
					'-webkit-font-smoothing': 'antialiased',
					'-moz-osx-font-smoothing': 'grayscale',
					height: '100%',
					width: '100%'
				},
				body: {
					height: '100%',
					width: '100%',
					fontSize: 14
				},
				'#root': {
					height: '100%',
					width: '100%'
				},
				a: {
					color: '#5B70FF'
				}
			}}
		/>
	)

	useEffect(() => {
		// Add event and data (userId) to data layer for Google tag manager variables
		if (user && Array.isArray(window.dataLayer)) {
			window.dataLayer.push({ event: 'userLoaded', userId: user.uid, userEmail: user.email })
		}
	}, [user])

	// Fallback usage since makeStyles isn't working
	document.body.dataset.theme = settings.theme

	useEffect(() => {
		if (isChatInitialized && currentPracticeObject?.id === currentChatPracitceId) {
			console.log(
				'App Use Effect return as chat is already initialized',
				isChatInitialized,
				currentPracticeObject?.id,
				currentChatPracitceId
			)
			return
		}

		if (currentPracticeObject?.isChatEnabled && user && currentPracticeObject) {
			console.log(
				'App Use Effect to call initPracticeConvos',
				isChatInitialized,
				currentPracticeObject.id,
				currentChatPracitceId
			)
			if (isChatInitialized && currentPracticeObject.id !== currentChatPracitceId) {
				const fetch = async () => {
					chatClient?.removeAllListeners()
				}
				fetch().catch(console.error)
			}

			// const practiceToken = await getToken(currentPracticeObject?.stripeAccountId);
			const fetch = async () => {
				try {
					const { initPracticeConvos } = await import('./util/twilio-helpers')
					const twilioClient = await initPracticeConvos(currentPracticeObject, user, dispatch)
					ChatClient.chatSDK = twilioClient
					setChatClient(twilioClient)
					setIsChatInitialized(true)
					setCurrentChatPracitceId(currentPracticeObject.id as string) // TODO: cuid?
				} catch (err) {
					console.error('error in App Use Effect to call initPracticeConvos')
					console.log(err)
				}
			}
			fetch().catch(console.error)
		}
	}, [user, currentPracticeObject])

	const ContactSupport = lazyWithRetry(() => import('./pages/errors/ContactSupport'))
	useEffect(() => {
		const init = async () => {
			import('./util/init-aggrid').then((module) => module.default())
		}
		init().catch(console.error)
	}, [])

	const shouldContactSupport = () => {
		const hasPrimaryClinic = user.primaryClinic
		const isLending = user.financingStatus === 'active' // Lending users don't have a primary clinic
		const hasPractices =
			user.practices && Array.isArray(user.practices) && user.practices.length >= 1

		if ((!hasPrimaryClinic && !isLending) || (!hasPractices && !user?.isScratchAdmin)) {
			return true
		}

		return false
	}

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<Toaster
					position="top-right"
					reverseOrder={false}
					gutter={8}
					containerClassName=""
					containerStyle={{
						top: 56
					}}
					toastOptions={{
						className: '',
						duration: 5000,
						style: {
							background: '#000000',
							color: '#fff',
							fontFamily:
								'-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
							fontSize: '1rem'
						},
						success: {
							style: {
								background: 'green'
							}
						},
						error: {
							style: {
								background: 'red'
							}
						}
					}}
				>
					{(t) => (
						<ToastBar toast={t}>
							{({ icon, message }) => (
								<>
									{icon}
									{message}
									{t.type !== 'loading' && (
										<Button type="submit" onClick={() => toast.dismiss(t.id)}>
											<CloseIcon titleAccess="Dismiss" />
										</Button>
									)}
								</>
							)}
						</ToastBar>
					)}
				</Toaster>
				<ReaderProvider>
					<CssBaseline />
					{useStyles}
					<SettingsDrawer />
					<Hooks />
					<ChatWidget />
					<Suspense fallback={<ResourceLoading />}>
						{user && shouldContactSupport() ? <ContactSupport /> : content}
					</Suspense>
					<DelightedSurvey />
				</ReaderProvider>
			</ThemeProvider>
		</QueryClientProvider>
	)
}

export default App
