import { useState } from 'react'
import { Box, Tooltip, Alert, AlertTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Info } from 'icons/Info'
import { useBannerStyles } from './BannerStyles'
import BannerButton from './BannerButton'
import { BannerVariant, BannerProps } from './banner.d'

export const Banner: React.FC<BannerProps> = ({
	title,
	subtitle,
	tooltip,
	href,
	buttonTitle,
	onButtonClick,
	customIcon,
	customActionButtons,
	disabled = false,
	showButton = true,
	showCloseButton = true,
	variant = BannerVariant.ERROR
}) => {
	const { classes } = useBannerStyles()
	const [isOpened, setIsOpened] = useState(true)

	if (!isOpened) {
		return null
	}

	return (
		<Box className={classes.container}>
			<Tooltip title={tooltip}>
				<Alert
					sx={{
						'& .MuiAlert-icon': {
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						},
						'& .MuiAlert-message': {
							py: '10px'
						}
					}}
					severity="error"
					className={classes[variant]}
					icon={
						customIcon || (
							<Info
								style={{
									alignSelf: 'center',
									color: '#1D1D30',
									fontSize: '2rem'
								}}
							/>
						)
					}
					action={
						<Box className={classes.actionBox}>
							{customActionButtons}
							{showButton && (
								<BannerButton {...{ href, buttonTitle, onButtonClick, variant, disabled }} />
							)}
							{showCloseButton && (
								<IconButton className={classes.closeButton} onClick={() => setIsOpened(false)}>
									<CloseIcon fontSize="small" />
								</IconButton>
							)}
						</Box>
					}
				>
					<AlertTitle className={classes.title}>{title}</AlertTitle>
					{subtitle}
				</Alert>
			</Tooltip>
		</Box>
	)
}
