import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { useCurrentPractice } from 'contexts/PracticeContext'
import FrontAppChatWidget from 'hooks/frontAppChatWidget'
import { selectUser } from 'selectors/auth'
import { shouldShowMavenChat as consolidatorShouldShowMavenChat } from 'util/consolidator'
import { shouldShowMavenChat as practiceShouldShowMavenChat } from 'util/practice'

import { MavenChatWidget } from './MavenChatWidget'

export const ChatWidget = () => {
	const currentUser = useSelector(selectUser)
	const { currentPracticeObject, currentUserConsolidator } = useCurrentPractice()

	const useMavenChat = useMemo(
		() =>
			practiceShouldShowMavenChat(currentPracticeObject) ??
			consolidatorShouldShowMavenChat(currentUserConsolidator) ??
			false,
		[currentPracticeObject, currentUserConsolidator]
	)

	if (!currentUser || isEmpty(currentPracticeObject)) {
		return null
	}

	if (useMavenChat) {
		return <MavenChatWidget />
	}

	return <FrontAppChatWidget />
}
