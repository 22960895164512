import { gql } from 'urql'
import { Mutation } from './generated-types/custom-graphql'

export const UPDATE_CONSOLIDATED_PRACTICE_STATUS = gql<Mutation>(`
  mutation updateConsolidatedPracticeStatus(
    $id: String!,
    $processType: String,
    $processStatus: String,
    ) {
      updateConsolidatedPracticeStatus(
      id: $id,
      processType: $processType,
      processStatus: $processStatus,
      ) {
        id
        status
    }
  }
`)
