import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCurrentPractice } from '../contexts/PracticeContext'
import { selectUser } from '../selectors/auth'
import { getUserHash } from '../apis/frontApp'
import { FRONT_APP_CHAT_ID } from '../config'

declare global {
	interface Window {
		FrontChat: any
	}
}

const FrontAppChatWidget = () => {
	const user = useSelector(selectUser)
	const [isChatInitialized, setIsChatInitialized] = useState(false)
	const { currentPracticeObject } = useCurrentPractice()

	const closeFrontAppChat = () => {
		window.FrontChat('shutdown', { clearSession: true })
	}

	const initChatWidget = async () => {
		try {
			const { userHash } = (await getUserHash()).data
			if (userHash) {
				window.FrontChat('init', {
					useDefaultLauncher: true,
					email: user?.email,
					chatId: FRONT_APP_CHAT_ID,
					userHash,
					name: user?.name,
					customFields: {
						'Stripe Account ID': currentPracticeObject?.primaryClinic,
						'Clinic Name': currentPracticeObject?.primaryClinicName,
						'Group ID': currentPracticeObject?.consolidatorId
					},
					onInitCompleted: () => {
						setIsChatInitialized(true)
					}
				})
			}
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		if (!currentPracticeObject) return
		if (!user) {
			if (isChatInitialized) {
				window.FrontChat('shutdown', { clearSession: true })
			}
			return
		}
		initChatWidget()

		return () => closeFrontAppChat() // clean up on unmount
	}, [user, currentPracticeObject])

	return null
}

export default FrontAppChatWidget
