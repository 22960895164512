import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

import { BannerVariant } from 'components/common/banner'
import BannerButton from 'components/common/banner/BannerButton'
import { useCurrentPractice } from 'contexts/PracticeContext'
import { selectUser } from 'selectors/auth'

import { PERSONA_PROCESS_TYPES } from '../../../../constants'
import { useGraphQLClient } from 'pages/dashboard/RefundRequest/helpers'
import { UPDATE_CONSOLIDATED_PRACTICE_STATUS } from 'gql/consolidatedPractice'

type CompleteInquiryButtonProps = {
	processType?: PERSONA_PROCESS_TYPES
	onCompleted?: () => void
}

export const CompleteInquiryButton: React.FC<CompleteInquiryButtonProps> = ({
	processType,
	onCompleted
}) => {
	const user = useSelector(selectUser)
	const { currentPracticeObject } = useCurrentPractice()

	const handleCompleteInquiry = async () => {
		const toastId = toast.loading('Updating process status...')

		try {
			const graphQLClient = useGraphQLClient({ practiceId: currentPracticeObject.id })
			const queryResult = await graphQLClient
				.mutation(UPDATE_CONSOLIDATED_PRACTICE_STATUS, {
					processType,
					id: currentPracticeObject?.consolidatedPractice?.id,
					processStatus: 'completed'
				})
				.toPromise()

			if (queryResult.error) {
				console.error(queryResult.error)
				return toast.error(
					'Something went wrong while updating the status. Please try again later',
					{
						id: toastId
					}
				)
			}

			toast.success('Status updated!', { id: toastId })
			onCompleted && onCompleted()
		} catch (err: any) {
			console.error(err)
			toast.error('Something went wrong while updating the status. Please try again later', {
				id: toastId
			})
		}
	}

	if (!user?.isScratchAdmin || !currentPracticeObject?.consolidatedPractice?.id) {
		return null
	}

	return (
		<BannerButton
			onButtonClick={handleCompleteInquiry}
			variant={BannerVariant.ERROR}
			buttonTitle="Mark as Done"
		/>
	)
}
