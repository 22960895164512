import { createClient } from 'urql'
import { gqlClientOptions } from 'gql'
import {
	QueryMode,
	RefundRequestOrderByInput,
	RefundRequestWhereInput,
	SortOrder
} from 'gql/generated-types/custom-graphql'
import {
	PRACTICE_ROLE_ACCOUNTING_MANAGER,
	PRACTICE_ROLE_HOSPITAL_MANAGER,
	PRACTICE_ROLE_REGIONAL_MANAGER,
	RequestLevel
} from '../../../constants'

type GetGraphQLClientOptionsProps = {
	user?: User
	consolidatorId?: string
	practiceId?: string
}

export const getGraphQLClientOptions = ({
	consolidatorId,
	practiceId,
	user
}: GetGraphQLClientOptionsProps) => {
	let requestLevel: RequestLevel

	if (
		user?.isAdmin ||
		user?.isScratchAdmin ||
		user?.initialRole === PRACTICE_ROLE_ACCOUNTING_MANAGER
	) {
		requestLevel = RequestLevel.ADMIN
	} else if (
		[PRACTICE_ROLE_HOSPITAL_MANAGER, PRACTICE_ROLE_REGIONAL_MANAGER].includes(
			user?.initialRole as string
		)
	) {
		requestLevel = RequestLevel.MANAGER
	} else {
		requestLevel = RequestLevel.PRACTICE
	}

	return {
		requestLevel,
		consolidatorId,
		practiceId
	}
}

export const useGraphQLClient = ({
	consolidatorId,
	practiceId,
	user
}: GetGraphQLClientOptionsProps) => {
	const clientOptions = getGraphQLClientOptions({
		consolidatorId,
		practiceId,
		user
	})

	return createClient(gqlClientOptions(clientOptions))
}

export const extractResultData = (queryResult) => {
	const totalCount = queryResult?.data?.listRefundRequests?.total ?? 0
	return {
		result: queryResult?.data?.listRefundRequests?.items ?? [],
		totalCount
	}
}

export const getRefundRequestApprovalLink = (refundRequestSettings?: IRefundRequest) =>
	refundRequestSettings?.approvalPimsLink ?? ''

const getRefundRequestsWhereClause = (
	statusFilter: string,
	tierFilter: string,
	queryFilter: string
) => {
	const where: RefundRequestWhereInput = {}

	if (statusFilter) {
		where.status = { equals: statusFilter }
	}

	if (tierFilter) {
		where.approverRole = { equals: tierFilter }
	}

	if (queryFilter) {
		where.OR = [
			{ pimsClientId: { contains: queryFilter, mode: QueryMode.Insensitive } },
			{ practice: { name: { contains: queryFilter, mode: QueryMode.Insensitive } } }
		]
	}

	return where
}

const getRefundRequestsOrderClause = (): RefundRequestOrderByInput[] => [
	{
		createdAt: SortOrder.Desc
	}
]

const mapTierToUserRole = (selectedTier: string, refundRequestSettings?: IRefundRequest) => {
	const tier =
		refundRequestSettings?.approverRoleCriteria &&
		refundRequestSettings?.approverRoleCriteria[selectedTier]
	if (tier) {
		return (tier as IRefundRequestTier)?.approver ?? selectedTier
	}
	return selectedTier
}

export const getRefundRequestsQueryVariables = (
	statusFilter: string,
	tierFilter: string,
	queryFilter: string,
	refundRequestSettings?: IRefundRequest
) => {
	const tier = mapTierToUserRole(tierFilter, refundRequestSettings)
	const where = getRefundRequestsWhereClause(statusFilter, tier, queryFilter)
	const orderBy = getRefundRequestsOrderClause()
	return { where, orderBy }
}

export const formatTier = (tier: string) => {
	const tierMap = {
		tier1: 'Tier 1',
		tier2: 'Tier 2',
		tier3: 'Tier 3'
	}
	return tierMap[tier] ?? tier
}

export const removeEscapeOnNotesApprover = (value?: string) =>
	value?.replaceAll('\\)', ')').replaceAll('\\%', '%')

export const mapUserRoleToTier = (userRole: string, refundRequestSettings?: IRefundRequest) => {
	const approverCriteria = refundRequestSettings?.approverRoleCriteria
	if (!approverCriteria) {
		return userRole
	}
	const tierMap = {
		[approverCriteria?.tier1?.approver]: 'tier1',
		[approverCriteria?.tier2?.approver]: 'tier2',
		[approverCriteria?.tier3?.approver]: 'tier3'
	}
	return tierMap[userRole] ?? ''
}
