import { gql } from 'urql'
import { Query } from './generated-types/custom-graphql'

export const PAYMENT_REQUEST_FILE = gql<Query>(`
  query PaymentRequestFileList ($skip: Int, $take: Int, $orderBy: [PaymentRequestFileOrderByInput!], $where: PaymentRequestFileWhereInput) {
    paymentRequestFiles (skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
        items {
            id,
            createdAt,
            createdBy,
            recordsCount,
            succeededRecordsCount,
            failureReason,
            failedRecordsCount,
            fileName,
            createdByUser {
                name,
                displayName
            }
        },
        total
    }
  }
`)
