import { useEffect, useRef, useState } from 'react'

import { MAVEN_AGI_CHAT_KEY } from '../../config'

declare global {
	interface Window {
		Maven: any
	}
}

export const MavenChatWidget = () => {
	const isInitializing = useRef(false)
	const [isChatInitialized, setIsChatInitialized] = useState(false)

	const initChatWidget = () => {
		if (isChatInitialized || isInitializing.current) {
			return
		}

		if (MAVEN_AGI_CHAT_KEY && window?.Maven?.ChatWidget) {
			isInitializing.current = true
			const tag = 'maven-chat-widget'

			window.Maven.ChatWidget.load({
				tag,
				apiKey: MAVEN_AGI_CHAT_KEY,
				location: 'bottom-right'
			}).then(() => {
				/**
				 * The Maven ChatWidget has certain limitations:
				 * 1. It doesn't support adding custom styles directly.
				 * 2. There is no built-in method to remove the widget from the page.
				 *
				 * To work around these limitations:
				 * - I'm adding custom classes to the widget, allowing us to apply additional styles.
				 * - These classes also help in detaching the widget later, since direct removal is not possible.
				 */
				const iframe = window.document.querySelector(`iframe[src*="${tag}"]`)

				if (iframe) {
					const button = iframe.previousElementSibling
					iframe.classList.add('maven-chat-widget')
					button?.classList?.add('maven-chat-widget', 'maven-button')
				}

				setIsChatInitialized(true)
				isInitializing.current = false
			})
		}
	}

	const closeChatWidget = () => {
		window?.Maven?.ChatWidget?.close()
		const chatElements = window.document.querySelectorAll('.maven-chat-widget')
		chatElements.forEach((element) => element.remove())
	}

	useEffect(() => {
		initChatWidget()
		return () => closeChatWidget()
	}, [])

	return null
}
